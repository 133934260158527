import { Helmet, HelmetProvider } from "react-helmet-async";
import React, { ReactPropTypes } from "react";
import { motion } from "framer-motion";
import ReactFullpage from "@fullpage/react-fullpage";

import Navbar from "../../components/nav/navbar-transparent";
import mergedVideos from "../../assets/home/videos/merged.mp4";
import loader from "../../assets/shared/loader.gif";
import mobileSectionOne from "../../assets/home/videos/mobile-section-one.mp4";
import mobileSectionTwo from "../../assets/home/videos/mobile-section-two.mp4";
import mobileSectionThree from "../../assets/home/videos/mobile-section-three.mp4";
import mobileSectionFour from "../../assets/home/videos/mobile-section-four.mp4";
import welcomeToBattleDronesText from "../../assets/home/welcome-to-battle-drones.png";
import discordIcon from "../../assets/shared/discord.png";
import instagramIcon from "../../assets/shared/instagram.png";

export default class Home extends React.Component {
	private video: HTMLVideoElement | undefined;
	private desktopVideoOverlay: HTMLDivElement | undefined;
	private leftArrow: HTMLButtonElement | undefined;
	private rightArrow: HTMLButtonElement | undefined;
	private sections: string[];
	private sectionTransitionDuration: number;
	private finalProp: number | undefined;
	private stepFrame = 0.15;

	constructor(props: ReactPropTypes) {
		super(props);
		this.state = {
			section: "welcome",
			video: "welcome",
			isMobile: this.isMobile(),
			showSection: true,
			videoLoaded: false,
			hasGifPlayed: false,
		};

		this.sections = [
			"welcome",
			"weapons",
			"rewards",
			"redRockResistance",
			"joinTheCrew",
		];
		this.sectionTransitionDuration = 1200;
		this.finalProp = 0;
	}

	componentDidMount(): void {
		this.video = document.getElementById(
			"background-video",
		) as HTMLVideoElement;
		this.desktopVideoOverlay = document.getElementById(
			"overlay",
		) as HTMLDivElement;

		this.leftArrow = document.getElementById("left-arrow") as HTMLButtonElement;
		this.rightArrow = document.getElementById(
			"right-arrow",
		) as HTMLButtonElement;
		this.leftArrow.style.opacity = "0";
		this.rightArrow.style.opacity = "0.75";
		/*this.video.pause();
		document.addEventListener("mousemove", async (event: MouseEvent) => {
			if (this.video !== undefined) {
				this.finalProp = this.calculateTime(event.x);
			}
		});
		setInterval(() => {
			if (this.video !== undefined) {
				this.video.currentTime = this.findTime();
			}
		}, 100);
*/
		this.video.addEventListener("timeupdate", () => {
			const [timestamp, duration] = this.getVideoPosition();

			if (this.video !== undefined) {
				if (this.video.currentTime >= timestamp + duration) this.video.pause();
			}
		});

		window.addEventListener("resize", () => {
			this.setState({ isMobile: this.isMobile() });
			this.setBackground();
		});

		document.body.classList.add("overflow-hidden");
	}

	findTime(): number {
		if (this.finalProp !== undefined && this.video) {
			const [timestamp, duration] = this.getVideoPosition();

			const targetTime = timestamp + duration * this.finalProp;
			const currentTime = this.video.currentTime;
			let nextTime;
			if (Math.abs(currentTime - targetTime) < this.stepFrame / 10) {
				nextTime = currentTime;
			} else if (Math.abs(currentTime - targetTime) < this.stepFrame) {
				nextTime = targetTime;
			} else if (currentTime < targetTime) {
				nextTime = currentTime + this.stepFrame;
			} else {
				nextTime = currentTime - this.stepFrame;
			}
			return nextTime;
		} else {
			return 0;
		}
	}

	calculateTime(xPos: number): number {
		const cutOff = 75;
		const totalMovement: number = xPos - cutOff;
		const totalDistance: number = window.innerWidth - 2 * cutOff;
		const screenProp: number = Math.max(
			0,
			Math.min(totalMovement / totalDistance, 1),
		);
		return screenProp;
	}

	getVideoPosition(): [timestamp: number, duration: number] {
		const timestamps: number[] = [0, 9, 14, 24, 36];
		const durations: number[] = [6, 3, 7.8, 11.8, 0]; // [6, 3, 8, 12, 0]

		const currentSection: number = this.sections.findIndex(
			(sections: string) => sections === (this.state as any).section,
		);

		let timestamp: number = timestamps[currentSection];
		let duration: number = durations[currentSection];

		return [timestamp, duration];
	}

	setBackground(): void {
		const currentSectionIndex: number = this.sections.findIndex(
			(section: string) => section === (this.state as any).section,
		);

		if (this.leftArrow !== undefined)
			this.leftArrow.style.opacity = `${
				currentSectionIndex / this.sections.length
			}`;
		if (this.rightArrow !== undefined)
			this.rightArrow.style.opacity = `${
				(this.sections.length - currentSectionIndex - 1) / this.sections.length
			}`;
	}

	transitionToSection(section: HomeSections): void {
		this.setState({ showSection: false });

		if (this.desktopVideoOverlay !== undefined) {
			this.desktopVideoOverlay.classList.remove("bg-opacity-70");
			this.desktopVideoOverlay.classList.add("bg-opacity-5");

			setTimeout(() => {
				this.desktopVideoOverlay?.classList.remove("bg-opacity-5");
				this.desktopVideoOverlay?.classList.add("bg-opacity-70");

				this.setState({ section, showSection: true });
				if (this.video) {
					const [timestamp] = this.getVideoPosition();
					this.video.currentTime = timestamp;
					this.video?.play();
				}
				const currentSectionIndex: number = this.sections.findIndex(
					(sectionList: string) => section === sectionList,
				);
				if (this.leftArrow !== undefined)
					this.leftArrow.style.opacity = `${
						currentSectionIndex / this.sections.length
					}`;
				if (this.rightArrow !== undefined)
					this.rightArrow.style.opacity = `${
						(this.sections.length - currentSectionIndex - 1) /
						this.sections.length
					}`;
			}, this.sectionTransitionDuration);
		}
	}

	isMobile(): boolean {
		return window.innerWidth <= 1024;
	}

	render(): JSX.Element {
		const showPage = (section: HomeSections): void => {
			this.transitionToSection(section);
			this.setBackground();
		};

		const previousSection = (scrollUp?: any): void => {
			const currentSectionIndex: number = this.sections.findIndex(
				(section: string) => section === (this.state as any).section,
			);
			const index: number =
				(this.sections.length + (currentSectionIndex - 1)) %
				this.sections.length;

			if (!(this.state as any).isMobile) {
				this.transitionToSection(this.sections[index] as any);
				this.setBackground();
			} else {
				if (scrollUp !== undefined) scrollUp();
				this.setState({ section: this.sections[index] });

				const video: HTMLElement | null = document.getElementById(
					`${(this.state as any).section}-mobile-video`,
				);

				if (video !== null) {
					(video as HTMLVideoElement).currentTime = 0;
					(video as HTMLVideoElement).play();
				}
			}
		};
		const nextSection = (scrollDown?: any): void => {
			const currentSectionIndex: number = this.sections.findIndex(
				(section: string) => section === (this.state as any).section,
			);
			const index: number =
				(this.sections.length + (currentSectionIndex + 1)) %
				this.sections.length;

			if (!(this.state as any).isMobile) {
				this.transitionToSection(this.sections[index] as any);
				this.setBackground();
			} else {
				if (scrollDown !== undefined) scrollDown();
				this.setState({ section: this.sections[index] });

				const video: HTMLElement | null = document.getElementById(
					`${(this.state as any).section}-mobile-video`,
				);

				if (video !== null) {
					(video as HTMLVideoElement).currentTime = 0;
					(video as HTMLVideoElement).play();
				}
			}
		};

		const downArrow = (): JSX.Element => (
			<svg
				width="31"
				height="15"
				viewBox="0 0 31 15"
				fill="currentColor"
				xmlns="http://www.w3.org/2000/svg">
				<path d="M16.779 13.2763L29.7239 0.420489C29.9582 0.187791 30.3364 0.187791 30.5707 0.420489V0.420489C30.8073 0.655471 30.8073 1.03818 30.5707 1.27316L17.6317 14.1231C16.4544 15.2923 14.5456 15.2923 13.3683 14.1231L0.429292 1.27316C0.192681 1.03818 0.192681 0.655469 0.429292 0.420487V0.420487C0.663602 0.18779 1.04179 0.18779 1.2761 0.420488L14.221 13.2763C14.9274 13.9778 16.0726 13.9778 16.779 13.2763Z" />
			</svg>
		);

		const leftArrow = (): JSX.Element => (
			<svg
				width="25"
				height="51"
				viewBox="0 0 25 51"
				fill="currentColor"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M2.87921 27.8283L24.3437 49.2927C24.7342 49.6833 24.7342 50.3164 24.3437 50.707V50.707C23.9532 51.0975 23.32 51.0975 22.9295 50.707L1.465 29.2425C-0.487625 27.2899 -0.487625 24.124 1.465 22.1714L22.9295 0.706955C23.32 0.31643 23.9532 0.31643 24.3437 0.706955V0.706955C24.7342 1.09748 24.7342 1.73064 24.3437 2.12117L2.87921 23.5856C1.70764 24.7572 1.70764 26.6567 2.87921 27.8283Z"
					stroke="black"
				/>
			</svg>
		);

		const rightArrow = (): JSX.Element => (
			<svg
				width="26"
				height="52"
				viewBox="0 0 26 52"
				fill="currentColor"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M22.4645 23.8788L1.00008 2.41429C0.609551 2.02376 0.609551 1.3906 1.00008 1.00008V1.00008C1.3906 0.609551 2.02376 0.609551 2.41429 1.00008L23.8788 22.4645C25.8314 24.4172 25.8314 27.583 23.8788 29.5356L2.41429 51.0001C2.02377 51.3906 1.3906 51.3906 1.00008 51.0001V51.0001C0.609551 50.6096 0.609551 49.9764 1.00008 49.5859L22.4645 28.1214C23.6361 26.9498 23.6361 25.0503 22.4645 23.8788Z"
					stroke="black"
				/>
			</svg>
		);

		const isSection = (section: HomeSections): boolean =>
			(this.state as any).section === section;

		const isMobile = (): boolean => (this.state as any).isMobile;

		const showSection = (): boolean =>
			(this.state as any).showSection && (this.state as any).hasGifPlayed;

		const showLoader = (): boolean =>
			!(this.state as any).videoLoaded || !(this.state as any).hasGifPlayed;

		return (
			<>
				<HelmetProvider>
					<Helmet>
						<title>Battle Drones | Home</title>
					</Helmet>
				</HelmetProvider>

				{!isMobile() && showLoader() && (
					<>
						<div className="flex justify-center items-center h-screen w-screen bg-black">
							<img
								src={loader}
								onLoad={() => {
									setTimeout(() => {
										this.setState({ hasGifPlayed: true });
									}, 6000);
								}}
								className="w-auto h-[35rem]"
								alt="Loading"
							/>
						</div>
					</>
				)}

				<section className="hidden lg:block">
					<motion.video
						animate={{ opacity: 1 }}
						initial={{ opacity: 0 }}
						transition={{ duration: 2 }}
						id="background-video"
						autoPlay
						preload="auto"
						playsInline
						muted
						className="-z-10 absolute h-screen w-screen object-cover"
						onLoadedData={() => this.setState({ videoLoaded: true })}>
						<source src={mergedVideos} type="video/mp4" />
						Your browser does not support HTML5 video.
					</motion.video>

					<div
						id="overlay"
						className={`flex flex-col justify-between backdrop-blur-sm h-screen w-screen items-center pb-12 bg-black ${
							isSection("joinTheCrew") ? "bg-opacity-100" : "bg-opacity-70"
						} transition duration-1000`}>
						<div className="w-full">
							<Navbar></Navbar>
						</div>

						{showSection() && (
							<>
								{isSection("welcome") && (
									<>
										<motion.div
											animate={{ opacity: 1 }}
											initial={{ opacity: 0 }}
											className="max-w-8xl sm:mx-20 px-4 sm:px-6 lg:px-8 place-self-start sm:mt-28 transition duration-300">
											<div className="flex flex-col gap-y-12">
												<img
													src={welcomeToBattleDronesText}
													className="w-auto h-24"
													alt="Welcome to Battle Drones"
												/>
												<p className="text-white text-xl md:text-xl max-w-lg font-['Exo']">
													An enjoyable and immersive metaverse ecosystem built
													for gamers by gamers
												</p>
												<div className="flex flex-row gap-x-2">
													<a
														href="https://www.youtube.com/watch?v=0K5NL9humC4"
														target="_blank"
														rel="noreferrer"
														className="hidden md:block bg-[#FF4C00] font-['Exo'] text-xl text-white py-2 rounded-md uppercase w-full md:w-1/4 transition-transform text-center hover:scale-105">
														Trailer
													</a>
													<a
														href="https://discord.com/invite/s82b8gZF5v"
														target="_blank"
														rel="noreferrer"
														className="hidden md:block bg-black font-['Exo'] bg-opacity-50 text-xl border-2 border-white text-white py-2 rounded-md uppercase w-full md:w-1/4 transition-transform text-center hover:scale-105">
														Join Discord
													</a>
												</div>
											</div>
										</motion.div>
									</>
								)}

								{isSection("weapons") && (
									<>
										<motion.div
											animate={{ opacity: 1 }}
											initial={{ opacity: 0 }}
											className="max-w-8xl sm:mx-20 px-4 sm:px-6 lg:px-8 place-self-start sm:mt-28">
											<div className="flex flex-col gap-y-12">
												<h1 className="text-5xl md:text-6xl text-white font-semibold drop-shadow-lg shadow-black font-['Exo']">
													Weapons
												</h1>
												<div>
													<p className="text-white font-medium text-lg md:text-2xl max-w-xl font-['Exo']">
														Choose the right arsenal for the job
													</p>
													<p className="mt-4 font-light text-white text-lg max-w-xl">
														You will have an array of weapons at your disposal
														to eliminate a variety of enemy types and challenge
														other players. Grab a melee weapon if you fancy
														yourself in hand-to-hand combat, or one of our
														ranged weapons if you favour shooting from a
														distance.
													</p>
												</div>
											</div>
										</motion.div>
									</>
								)}

								{isSection("rewards") && (
									<>
										<motion.div
											animate={{ opacity: 1 }}
											initial={{ opacity: 0 }}
											className="max-w-8xl sm:mx-20 px-4 sm:px-6 lg:px-8 place-self-start sm:mt-28">
											<div className="flex flex-col gap-y-12">
												<h1 className="text-5xl md:text-6xl text-white font-semibold drop-shadow-lg shadow-black font-['Exo']">
													Rewards
												</h1>

												<div>
													<p className="text-white font-medium text-lg md:text-2xl max-w-xl font-['Exo']">
														Earn tokens and collect assets
													</p>
													<p className="mt-4 font-light text-white text-lg max-w-xl">
														You as a player will be rewarded by progressing
														through the game and unlocking hidden in-game
														achievements. Our community leaderboards, <br />
														MMR ranking and monthly challenges will be used to
														determine your rewards.
													</p>
												</div>
											</div>
										</motion.div>
									</>
								)}

								{isSection("redRockResistance") && (
									<>
										<motion.div
											animate={{ opacity: 1 }}
											initial={{ opacity: 0 }}
											className="max-w-8xl sm:mx-20 px-4 sm:px-6 lg:px-8 place-self-start sm:mt-28">
											<div className="flex flex-col gap-y-12">
												<h1 className="text-5xl md:text-6xl text-white font-semibold drop-shadow-lg shadow-black font-['Exo']">
													Red Rock Resistance
												</h1>

												<div>
													<p className="text-white font-medium text-lg md:text-2xl max-w-2xl font-['Exo']">
														A 3D isometric drone-shooter game
													</p>
													<p className="mt-8 font-light text-white text-lg max-w-xl">
														A play-to-earn PC game, focused on introducing an
														immersive and enjoyable shooter game experience. Red
														Rock Resistance is the first opportunity for our
														community to engage within the Battle Drones
														ecosystem.
													</p>
													<p className="mt-8 font-light text-white text-lg max-w-xl">
														Are you ready for Battle?
													</p>
												</div>
											</div>
										</motion.div>
									</>
								)}

								{isSection("joinTheCrew") && (
									<>
										<motion.div
											animate={{ opacity: 1 }}
											initial={{ opacity: 0 }}
											className="max-w-8xl sm:mx-20 px-4 sm:px-6 lg:px-8 justify-center items-center sm:mt-28">
											<div className="flex flex-col gap-y-12">
												<h1 className="text-5xl md:text-6xl text-white font-semibold text-center font-['Exo'] drop-shadow-lg shadow-black">
													Join The Crew
												</h1>

												<p className="text-white font-medium text-lg md:text-2xl max-w-xl font-['Exo'] text-center">
													Join our immersive ecosystem as we embark on
													revolutionising the gaming industry
												</p>

												<div className="flex flex-row justify-center items-center gap-x-4">
													<a
														className="hover:scale-105"
														target="_blank"
														href="https://twitter.com/BattleDronesNFT"
														rel="noreferrer">
														<svg
															className="h-10 w-10 text-white"
															fill="currentColor"
															viewBox="0 0 24 24"
															aria-hidden="true">
															<path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
														</svg>
													</a>

													<a
														className="hover:scale-105"
														href="https://discord.com/invite/s82b8gZF5v"
														target="_blank"
														rel="noreferrer">
														<img
															src={discordIcon}
															className="w-12 h-12 hover:scale-105"
															alt="Discord"
														/>
													</a>

													<a
														href="https://www.instagram.com/battledronesnft/"
														target="_blank"
														rel="noreferrer"
														className="hover:scale-105">
														<img
															src={instagramIcon}
															className="w-8 h-8 hover:scale-105"
															alt="Instagram"
														/>
													</a>
												</div>
											</div>
										</motion.div>
									</>
								)}
							</>
						)}

						<div className="max-w-8xl place-self-center flex flex-row gap-x-4">
							<button
								className={`bg-white transition duration-200 w-7 lg:w-8 h-1 ${
									isSection("welcome")
										? "bg-opacity-100"
										: "bg-opacity-50 hover:bg-opacity-100"
								}`}
								onClick={() => showPage("welcome")}></button>
							<button
								className={`bg-white transition duration-200 w-7 lg:w-8 h-1 ${
									isSection("weapons")
										? "bg-opacity-100"
										: "bg-opacity-50 hover:bg-opacity-100"
								}`}
								onClick={() => showPage("weapons")}></button>
							<button
								className={`bg-white transition duration-200 w-7 lg:w-8 h-1 ${
									isSection("rewards")
										? "bg-opacity-100"
										: "bg-opacity-50 hover:bg-opacity-100"
								}`}
								onClick={() => showPage("rewards")}></button>
							<button
								className={`bg-white transition duration-200 w-7 lg:w-8 h-1 ${
									isSection("redRockResistance")
										? "bg-opacity-100"
										: "bg-opacity-50 hover:bg-opacity-100"
								}`}
								onClick={() => showPage("redRockResistance")}></button>
							<button
								className={`bg-white transition duration-200 w-7 lg:w-8 h-1 ${
									isSection("joinTheCrew")
										? "bg-opacity-100"
										: "bg-opacity-50 hover:bg-opacity-100"
								}`}
								onClick={() => showPage("joinTheCrew")}></button>
						</div>

						<button
							id="left-arrow"
							className={`text-white hover:scale-105 absolute top-1/2 left-4 md:left-8 transition ${
								isSection("welcome") || !showSection() || showLoader()
									? "hidden"
									: "block"
							}`}
							onClick={() => previousSection()}>
							{leftArrow()}
						</button>

						<button
							id="right-arrow"
							className={`text-white hover:scale-105 absolute top-1/2 right-4 md:right-8 transition ${
								isSection("joinTheCrew") || !showSection() || showLoader()
									? "hidden"
									: "block"
							}`}
							onClick={() => nextSection()}>
							{rightArrow()}
						</button>
					</div>
				</section>

				{isMobile() && (
					<>
						{showLoader() && (
							<>
								<div className="flex justify-center items-center h-screen w-screen bg-black">
									<img
										src={loader}
										onLoad={() => {
											setTimeout(() => {
												this.setState({ hasGifPlayed: true });
											}, 6000);
										}}
										className="w-auto h-96"
										alt="Loading"
									/>
								</div>
							</>
						)}

						<section className="block lg:hidden">
							<Navbar></Navbar>

							<ReactFullpage
								licenseKey="6565A5A4-77F64D63-B370ED3B-C3A8D028"
								scrollOverflow={false}
								scrollingSpeed={1000}
								onLeave={(_0: any, _1: any, direction: "up" | "down") => {
									if (direction === "down") nextSection();
									else previousSection();
								}}
								render={({ state, fullpageApi }: any) => {
									return (
										<ReactFullpage.Wrapper>
											<div className="section">
												<motion.section
													initial={{ opacity: 0 }}
													whileInView={{ opacity: 1 }}
													viewport={{ once: true }}>
													<video
														autoPlay
														id="welcome-mobile-video"
														preload="auto"
														playsInline
														muted
														className="-z-10 absolute block h-screen w-screen object-cover">
														<source src={mobileSectionOne} type="video/mp4" />
														Your browser does not support HTML5 video.
													</video>

													<div className="flex flex-col justify-end h-screen w-screen items-center bg-gradient-to-b from-transparent to-black bg-opacity-50">
														<div className="flex flex-col justify-between px-2 pt-2 pb-8">
															<div className="flex flex-col gap-y-4 bg-black rounded-md bg-opacity-50 px-6 pt-6 pb-20 max-w-sm w-full">
																<h1 className="text-2xl text-white  font-['Exo'] max-w-sm uppercase">
																	Welcome to
																</h1>
																<h1 className="-mt-4 uppercase text-4xl text-white  font-['Stargaze']">
																	Battle Drones
																</h1>
																<p className="text-white font-light text-lg max-w-lg pb-10">
																	An enjoyable and immersive metaverse ecosystem
																	built for gamers by gamers
																</p>
																<div className="flex flex-col gap-y-2 -mt-4">
																	<a
																		href="https://www.youtube.com/watch?v=0K5NL9humC4"
																		target="_blank"
																		rel="noreferrer"
																		className="text-white text-sm uppercase py-3 px-2 rounded-md max-w-[10rem] bg-[#FF4C00] font-semibold text-center hover:scale-105">
																		Trailer
																	</a>
																	<a
																		href="https://discord.com/invite/s82b8gZF5v"
																		target="_blank"
																		rel="noreferrer"
																		className="text-white text-sm uppercase py-3 px-2 rounded-md max-w-[10rem] border-2 border-white bg-black font-semibold bg-opacity-50 text-center hover:scale-105">
																		Join Discord
																	</a>
																</div>
															</div>
														</div>
													</div>
												</motion.section>
											</div>
											<div className="section">
												<motion.section
													initial={{ opacity: 0 }}
													whileInView={{ opacity: 1 }}
													viewport={{ once: true }}>
													<video
														autoPlay
														id="weapons-mobile-video"
														preload="auto"
														playsInline
														muted
														className="-z-10 absolute block h-screen w-screen object-cover">
														<source src={mobileSectionTwo} type="video/mp4" />
														Your browser does not support HTML5 video.
													</video>

													<div className="flex flex-col justify-center backdrop-blur-sm h-screen w-screen items-center bg-black bg-opacity-50 relative">
														<div className="flex flex-col justify-between p-2  ">
															<div className="flex flex-col gap-y-6 rounded-lg p-4 ">
																<h1 className="text-4xl text-white font-semibold font-['Exo']">
																	Weapons
																</h1>
																<div>
																	<p className="text-white font-medium text-2xl max-w-xs font-['Exo']">
																		Choose the right arsenal for the job
																	</p>
																	<p className="mt-4 font-light text-white text-lg max-w-xs">
																		You will have an array of weapons at your
																		disposal to eliminate a variety of enemy
																		types and challenge other players. Grab a
																		melee weapon if you fancy yourself in
																		hand-to-hand combat, or one of our ranged
																		weapons if you favour shooting from a
																		distance.
																	</p>
																</div>
															</div>
														</div>

														<button
															className="text-white hover:scale-105 absolute left-1/2 bottom-24 animate-bounce transition"
															onClick={() =>
																nextSection(fullpageApi.moveSectionDown)
															}>
															{downArrow()}
														</button>
													</div>
												</motion.section>
											</div>
											<div className="section">
												<motion.section
													initial={{ opacity: 0 }}
													whileInView={{ opacity: 1 }}
													viewport={{ once: true }}>
													<video
														autoPlay
														id="rewards-mobile-video"
														preload="auto"
														playsInline
														muted
														className="-z-10 absolute block h-screen w-screen object-cover">
														<source src={mobileSectionThree} type="video/mp4" />
														Your browser does not support HTML5 video.
													</video>

													<div className="flex flex-col justify-center backdrop-blur-sm h-screen w-screen items-center bg-black bg-opacity-50 relative">
														<div className="flex flex-col justify-between p-2">
															<div className="flex flex-col gap-y-6 p-4">
																<h1 className="text-4xl text-white font-semibold font-['Exo']">
																	Rewards
																</h1>
																<p className="text-white font-medium text-2xl max-w-xs font-['Exo']">
																	Earn tokens and collect assets
																</p>
																<p className="text-white font-light text-lg max-w-xs">
																	You as a player will be rewarded by
																	progressing through the game and unlocking
																	hidden in-game achievements. Our community
																	leaderboards, MMR ranking and monthly
																	challenges will be used to determine your
																	rewards.
																</p>
															</div>
														</div>

														<button
															className="text-white hover:scale-105 absolute left-1/2 bottom-24 animate-bounce transition"
															onClick={() =>
																nextSection(fullpageApi.moveSectionDown)
															}>
															{downArrow()}
														</button>
													</div>
												</motion.section>
											</div>
											<div className="section">
												<motion.section
													initial={{ opacity: 0 }}
													whileInView={{ opacity: 1 }}
													viewport={{ once: true }}>
													<video
														autoPlay
														preload="auto"
														playsInline
														id="redRockResistance-mobile-video"
														muted
														className="-z-10 absolute block h-screen w-screen object-cover">
														<source src={mobileSectionFour} type="video/mp4" />
														Your browser does not support HTML5 video.
													</video>

													<div className="flex flex-col justify-center backdrop-blur-sm h-screen w-screen items-center bg-black bg-opacity-50 relative">
														<div className="flex flex-col justify-between p-2">
															<div className="flex flex-col gap-y-6 p-4">
																<h1 className="text-4xl text-white font-semibold font-['Exo'] max-w-sm">
																	Red Rock <br />
																	Resistance
																</h1>

																<p className="text-white font-medium text-2xl max-w-xs font-['Exo']">
																	A 3D isometric drone-shooter game
																</p>
																<p className="text-white font-light text-lg max-w-xs">
																	A play-to-earn PC game, focused on introducing
																	an immersive and enjoyable shooter game
																	experience. Red Rock Resistance is the first
																	opportunity for our community to engage within
																	the Battle Drones ecosystem.
																</p>
																<p className="text-white font-light text-lg max-w-xs">
																	Are you ready for Battle?
																</p>
															</div>
														</div>

														<button
															className="text-white hover:scale-105 absolute left-1/2 bottom-24 animate-bounce transition"
															onClick={() =>
																nextSection(fullpageApi.moveSectionDown)
															}>
															{downArrow()}
														</button>
													</div>
												</motion.section>
											</div>
											<div className="section">
												<motion.section
													initial={{ opacity: 0 }}
													whileInView={{ opacity: 1 }}
													viewport={{ once: true }}>
													<div className="flex flex-col justify-center backdrop-blur-sm h-screen w-screen items-center bg-black relative">
														<div className="flex flex-col justify-between p-2">
															<div className="flex flex-col gap-y-6 p-4">
																<h1 className="text-5xl text-white font-semibold font-['Exo'] max-w-sm text-center">
																	Join The Crew
																</h1>

																<p className="text-white font-medium text-xl max-w-xs font-['Exo'] text-center">
																	Join our immersive ecosystem as we embark on
																	revolutionising the gaming industry
																</p>
																<div className="flex flex-row justify-center items-center gap-x-4">
																	<a
																		className="hover:scale-105"
																		target="_blank"
																		href="https://twitter.com/BattleDronesNFT"
																		rel="noreferrer">
																		<svg
																			className="h-10 w-10 text-white"
																			fill="currentColor"
																			viewBox="0 0 24 24"
																			aria-hidden="true">
																			<path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
																		</svg>
																	</a>

																	<a
																		className="hover:scale-105"
																		href="https://discord.com/invite/s82b8gZF5v"
																		target="_blank"
																		rel="noreferrer">
																		<img
																			src={discordIcon}
																			className="w-12 h-12 hover:scale-105"
																			alt="Discord"
																		/>
																	</a>

																	<a
																		href="https://www.instagram.com/battledronesnft/"
																		target="_blank"
																		rel="noreferrer"
																		className="hover:scale-105">
																		<img
																			src={instagramIcon}
																			className="w-8 h-8 hover:scale-105"
																			alt="Instagram"
																		/>
																	</a>
																</div>
															</div>
														</div>
													</div>
												</motion.section>
											</div>
										</ReactFullpage.Wrapper>
									);
								}}
							/>
						</section>
					</>
				)}
			</>
		);
	}
}

type HomeSections =
	| "welcome"
	| "weapons"
	| "rewards"
	| "redRockResistance"
	| "joinTheCrew";
