import { Helmet, HelmetProvider } from "react-helmet-async";
import React, { ReactPropTypes, useState, useEffect } from "react";
import { motion } from "framer-motion";
import Drones from "../../assets/rarity/All_metadata_ranked.json";

import Navbar from "../../components/nav/navbar-transparent";
import FilterDesktop from "../../components/routes/rarity/filter/FilterDesktop";
import FilterMobile from "../../components/routes/rarity/filter/FilterMobile";
import NftGridItem from "../../components/routes/rarity/NftGridItem";
import RarityPages from "../../components/routes/rarity/Pages";
import ModalDesktop from "../../components/routes/rarity/modals/ModalDesktop";
import ModalMobile from "../../components/routes/rarity/modals/ModalMobile";

export default function Rarity() {
	const maximumNumberOfNftsToShow = 20;
	const options = [
		{
			value: "Rarity - Highest to Lowest",
			label: "Rarity - Highest to Lowest",
		},
		{
			value: "Rarity - Lowest to Highest",
			label: "Rarity - Lowest to Highest",
		},
		{
			value: "ID - Highest to Lowest",
			label: "ID - Highest to Lowest",
		},
		{
			value: "ID - Lowest to Highest",
			label: "ID - Lowest to Highest",
		},
	];
	const breakpoint = 1000;

	const [shown, setShown] = useState<JSX.Element[]>();
	const [changedDisplay, setChangedDisplay] = useState(0);
	const [shownDrones, setShownDrones] = useState(Drones);
	const [maxPages, setMaxPages] = useState(
		Math.ceil(Drones.length / maximumNumberOfNftsToShow),
	);
	const [page, setPage] = useState(2);
	const [order, setOrder] = useState("Rarity - Highest to Smallest");
	const [reading, setReading] = useState("");
	const [pagesShown, setPagesShown] = useState<JSX.Element[]>();
	const [isPhone, setIsPhone] = React.useState(window.innerWidth < breakpoint);
	const [currentDrone, setCurrentDrone] = useState(Drones[0]);
	const [show, setShow] = useState(false);

	useEffect(() => {
		window.addEventListener("resize", () => {
			setIsPhone(window.innerWidth < breakpoint);
		});
	}, []);

	useEffect(() => {
		(async () => {
			const nftItems: JSX.Element[] = [];
			setShown([]);

			const loaded: boolean[] = new Array(maximumNumberOfNftsToShow).fill(
				false,
			);

			document.getElementById("nft-rarity-spinner")?.classList.remove("hidden");
			document.getElementById("nft-rarity-spinner")?.classList.add("block");

			document.getElementById("nft-rarity-content")?.classList.add("hidden");

			document.getElementById("nft-rarity-pages")?.classList.add("hidden");
			const maxTime = setTimeout(() => {
				document.getElementById("nft-rarity-spinner")?.classList.add("hidden");
				document
					.getElementById("nft-rarity-spinner")
					?.classList.remove("d-block");
				document
					.getElementById("nft-rarity-content")
					?.classList.remove("hidden");
				document.getElementById("nft-rarity-pages")?.classList.remove("hidden");
			}, 20000);

			for (
				let i = (page - 1) * maximumNumberOfNftsToShow;
				i < page * maximumNumberOfNftsToShow && i < shownDrones.length;
				i++
			) {
				const currentDrone = shownDrones[i];

				nftItems.push(
					<NftGridItem
						timeout={maxTime}
						key={i.toString()}
						index={i}
						drone={currentDrone}
						onClick={showModal}
						loaded={loaded}
						maximumNumberOfItems={maximumNumberOfNftsToShow}
					/>,
				);
			}
			if (nftItems.length < maximumNumberOfNftsToShow) {
				for (let i = nftItems.length; i < maximumNumberOfNftsToShow; i++) {
					loaded[i] = true;
				}
			}
			setShown(nftItems);
		})();
	}, [changedDisplay, page, shownDrones]);

	useEffect(() => {
		const findNumber = (drone: any) => parseInt(drone.name.split("#")[1]) || -1;

		(async () => {
			if (reading !== "") {
				const readingActual = parseInt(reading).toString();
				const newDrone = Drones.filter(Drone => {
					const stringNumber = findNumber(Drone).toString();
					return stringNumber.startsWith(readingActual);
				});
				setShownDrones(newDrone);
				setMaxPages(Math.ceil(newDrone.length / maximumNumberOfNftsToShow));
			} else {
				setShownDrones(Drones);
				setMaxPages(Math.ceil(Drones.length / maximumNumberOfNftsToShow));
			}
			setPage(1);
			setChangedDisplay((changedDisplay: number) => changedDisplay + 1);
		})();
	}, [reading]);

	useEffect(() => {
		const findNumber = (drone: any) => parseInt(drone.name.split("#")[1]) || -1;

		(async () => {
			const newDrones = shownDrones;
			switch (order) {
				case "Rarity - Highest to Lowest":
					newDrones.sort((a, b) => a.rank - b.rank);
					break;
				case "Rarity - Lowest to Highest":
					newDrones.sort((a, b) => b.rank - a.rank);
					break;
				case "ID - Lowest to Highest":
					newDrones.sort((a, b) => findNumber(a) - findNumber(b));
					break;
				case "ID - Highest to Lowest":
					newDrones.sort((a, b) => findNumber(b) - findNumber(a));
					break;
			}
			setShownDrones(newDrones);
			setChangedDisplay((changedDisplay: number) => changedDisplay + 1);
			setPage(1);
		})();
	}, [order, shownDrones]);

	useEffect(() => {
		(async () => {
			const handleClick = (event: any) => {
				const numberString = event.target.id;
				if (numberString === "next") {
					setPage(page + 1);
				} else if (numberString === "before") {
					setPage(page - 1);
				} else {
					setPage(parseInt(numberString));
				}
			};

			const pages: JSX.Element[] = RarityPages({
				currentPage: page,
				maxPages,
				handleClick,
			});

			setPagesShown(pages);
		})();
	}, [page, maxPages]);

	const search = (input: any) => {
		const newInput = input.nativeEvent.data;
		if (newInput == null) {
			if (input.nativeEvent.inputType === "deleteContentBackward") {
				if (reading.length > 0) {
					setReading(reading.slice(0, -1));
				}
			} else {
				setReading("");
			}
		} else if (newInput >= "0" && newInput <= "9") {
			setReading(reading + newInput);
		}
	};

	const showModal = (event: any) => {
		const nameCurrent = event.target.alt;
		const droneClicked =
			Drones.filter(drone => drone.name === nameCurrent).pop() ?? Drones[0];
		setCurrentDrone(droneClicked);
		setShow(true);
	};

	const handleChange = (selectedOption: any) => {
		if (selectedOption.label) setOrder(selectedOption.label);
	};

	const handleClose = () => {
		setShow(false);
	};

	const disp = (
		<section
			data-bs-version="5.1"
			className="team1 cid-sINFwM47fv"
			id="team1-1e">
			<div className="container">
				<div id="nft-rarity-spinner" className="flex justify-center">
					<div className="animate-spin rounded-full h-20 w-20 border-b-4 border-white"></div>
				</div>

				<div
					id="nft-rarity-content"
					className="flex flex-wrap align-center justify-center">
					{shown}
				</div>
				<div id="nft-rarity-pages" className="text-right text-white">
					{pagesShown}
				</div>
				<div className="pb-[2rem]"></div>
			</div>
		</section>
	);

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>Battle Drones | Rarity</title>
				</Helmet>
			</HelmetProvider>
			{!isPhone && (
				<ModalDesktop
					show={show}
					handleClose={handleClose}
					animate={false}
					drone={currentDrone}
				/>
			)}
			{isPhone && (
				<ModalMobile
					show={show}
					handleClose={handleClose}
					animate={false}
					drone={currentDrone}
				/>
			)}

			<section className="hidden lg:block">
				<div
					id="overlay"
					className="flex-col justify-between backdrop-blur-sm w-screen min-h-screen bg-grey bg-opacity-100 transition duration-1000">
					<div className="w-full">
						<Navbar></Navbar>
					</div>
					<motion.div
						animate={{ opacity: 1 }}
						initial={{ opacity: 0 }}
						className="max-w-8xl sm:mx-20 px-4 sm:px-6 lg:px-8 place-self-center transition duration-300">
						<div className=" flex flex-row gap-y-12 ">
							<div className="mt-[8rem] md:h-[9rem] container mx-auto">
								<div className="row justify-content-center">
									<div className="col-12">
										<h3
											className="text-center mb-[1.5rem] text-5xl"
											style={{
												fontFamily: "Exo",
												fontWeight: "bold",
												color: "white",
											}}>
											RARITY TOOL
										</h3>
									</div>
								</div>

								<FilterDesktop
									search={{
										placeholder: "Enter Drone Number",
										onChange: search,
										value: reading,
									}}
									select={{
										options: options,
										onChange: handleChange,
										closeMenuOnSelect: true,
										defaultValue: options[0],
									}}
								/>

								{isPhone && (
									<FilterMobile
										search={{
											placeholder: "Enter Drone Number",
											onChange: search,
											value: reading,
										}}
										select={{
											options: options,
											onChange: handleChange,
											closeMenuOnSelect: true,
											defaultValue: options[0],
										}}
									/>
								)}
							</div>
						</div>
						{disp}
					</motion.div>
				</div>
			</section>

			<section className="block lg:hidden bg-grey">
				<Navbar></Navbar>
				<motion.div
					animate={{ opacity: 1 }}
					initial={{ opacity: 0 }}
					className="max-w-8xl sm:mx-20 px-4 sm:px-6 lg:px-8 place-self-center transition duration-300 ">
					<div className=" flex flex-row gap-y-12 pb-[2rem]">
						<div className="mt-[8rem]  container mx-auto">
							<div className="row justify-content-center">
								<div className="col-12">
									<h3
										className="text-center mb-[1.5rem] text-3xl"
										style={{
											fontFamily: "Exo",
											fontWeight: "bold",
											color: "white",
										}}>
										RARITY TOOL
									</h3>
								</div>
							</div>
							<FilterMobile
								search={{
									placeholder: "Enter Drone Number",
									onChange: search,
									value: reading,
								}}
								select={{
									options: options,
									onChange: handleChange,
									closeMenuOnSelect: true,
									defaultValue: options[0],
								}}
							/>
							<div className="pb-[2rem]"></div>
						</div>
					</div>
					{disp}
				</motion.div>
			</section>
		</>
	);
}
