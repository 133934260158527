import React from "react";
import { Modal } from "react-bootstrap";
import { RarityModalProps } from "./ModalTypes";

export default function ModalMobile(props: RarityModalProps) {
	return (
		<Modal
			className="align-center newModalPhone"
			show={props.show}
			onHide={props.handleClose}
			animation={props.animate}>
			<Modal.Body>
				<h3 className="align-left mbr-section-title mbr-fonts-style display-11 text-black">
					Rarity Rank #{props.drone.rank}
				</h3>
				<div className="image-wrap box-image">
					<img src={props.drone.image} alt={props.drone.name} />
				</div>
				<div style={{ paddingBottom: "1rem" }}></div>
				<div className="container-banner">
					<img
						src="assets/images/borderPhoneSmall.png"
						style={{
							margin: "auto",
							width: "185px",
						}}
						alt=""
					/>
					<h3 className=" mbr-section-title mbr-fonts-style align-center mb-6 display-12 text-black centered centeredPhone">
						ID: #{props.drone.name.split("#")[1]}
					</h3>
				</div>
				<div style={{ paddingBottom: "1rem" }}></div>
				<h3 className="mbr-section-title mbr-fonts-style align-left display-11 text-black">
					Rarity Score:
				</h3>
				<div className="container-banner">
					<img
						src="assets/images/borderPhone.png"
						style={{
							margin: "auto",
							maxWidth: "500px",
						}}
						alt=""
					/>
					<h3 className="mbr-section-title mbr-fonts-style align-center mb-6 display-12 text-black centered2 centered2Phone">
						{props.drone.score.toFixed(1)}
					</h3>
				</div>
				<div style={{ paddingBottom: "1rem" }}></div>

				<div className="flex-container-rare">
					<div className="flex-child2">
						<h4
							className="mbr-section-subtitle align-center mbr-fonts-style display-9"
							style={{
								color: "#818181",
								paddingTop: "1.5rem",
								paddingLeft: "0.5rem",
							}}>
							<strong>&#9432;</strong>
						</h4>
					</div>
					<div className="flex-child2">
						<h4
							className="mbr-section-subtitle align-left mbr-fonts-style display-9"
							style={{ color: "#818181", paddingTop: "1.5rem" }}>
							<strong>
								The Battle Drones Rarity chart is based on our first release and
								may change with our second release of NFTs
							</strong>
						</h4>
					</div>
				</div>
				<div style={{ paddingBottom: "1rem" }}></div>
				<div className="paddingSide">
					<h3
						style={{ fontWeight: 700, fontSize: "1.5rem" }}
						className="mbr-section-title mbr-fonts-style align-left mb-6 display-2 text-black">
						Attributes:
					</h3>
					<h3 className="mbr-section-title mbr-fonts-style align-left mb-6 display-2 text-black">
						BACKGROUND
					</h3>
					<div className="box-text-attributes">
						<h3 className="align-left display-2 text-black text-inside">
							{props.drone.attributes[0].value}
						</h3>
					</div>
					<h3 className="mbr-section-title mbr-fonts-style align-left mb-6 display-2 text-black">
						PROPELLERS
					</h3>
					<div className="box-text-attributes">
						<h3 className="align-left display-2 text-black text-inside">
							{props.drone.attributes[1].value}
						</h3>
					</div>
					<h3 className="mbr-section-title mbr-fonts-style align-left mb-6 display-2 text-black">
						BODY
					</h3>
					<div className="box-text-attributes">
						<h3 className="align-left display-2 text-black text-inside">
							{props.drone.attributes[2].value}
						</h3>
					</div>
					<h3 className="mbr-section-title mbr-fonts-style align-left mb-6 display-2 text-black">
						HEAD
					</h3>
					<div className="box-text-attributes">
						<h3 className="align-left display-2 text-black text-inside">
							{props.drone.attributes[3].value}
						</h3>
					</div>
					<h3 className="mbr-section-title mbr-fonts-style align-left mb-6 display-2 text-black">
						WEAPON
					</h3>
					<div className="box-text-attributes">
						<h3 className="align-left display-2 text-black text-inside">
							{props.drone.attributes[4].value}
						</h3>
					</div>
				</div>
				<div style={{ paddingBottom: "2rem" }}></div>
			</Modal.Body>
			<div style={{ paddingBottom: "3rem" }}></div>
			<button onClick={props.handleClose}>
				<div className="close-btn-div topright">
					<span style={{ display: "table-cell", verticalAlign: "middle" }}>
						<span onClick={props.handleClose} className="close-button">
							&times;
						</span>
					</span>
				</div>
			</button>
		</Modal>
	);
}
