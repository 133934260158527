import React from "react";
import { RarityFilterProps } from "./FilterTypes";
import Select from "react-select";

export default function FilterMobile(props: RarityFilterProps) {
	return (
		<div>
			<div
				style={{
					height: "42px",
					margin: "auto",
					width: "100%",
					maxWidth: "500px",
					fontFamily: "Exo",
					fontWeight: 400,
				}}>
				<input
					style={{
						height: "100%",
						width: "100%",
						padding: "4px 10px",
						fontFamily: "Exo",
						fontWeight: 400,
					}}
					className="display-10 mbr-section-title card-title mbr-fonts-style align-left m-0"
					placeholder={props.search.placeholder}
					type="search"
					onChange={props.search.onChange}
					value={props.search.value}
				/>
			</div>
			<div className="pb-[2rem]"></div>
			<div
				style={{
					height: "42px",
					margin: "auto",
					width: "100%",
					maxWidth: "500px",
					fontFamily: "Exo",
					fontWeight: 400,
				}}>
				<div
					style={{
						height: "100%",
						width: "100%",
						fontFamily: "Exo",
						fontWeight: 400,
					}}>
					<Select
						className="display-10 mbr-section-title card-title mbr-fonts-style align-left m-0"
						id="selectMenu"
						options={props.select.options}
						closeMenuOnSelect={props.select.closeMenuOnSelect}
						onChange={props.select.onChange}
						defaultValue={props.select.defaultValue}
					/>
				</div>
			</div>
		</div>
	);
}
