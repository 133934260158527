import React from "react";

export interface RarityPagesProps {
	currentPage: number;
	maxPages: number;
	handleClick: (event: any) => void;
}

export default function RarityPages(props: RarityPagesProps): JSX.Element[] {
	const pages: JSX.Element[] = [];
	let number = -1;
	let classNames =
		props.currentPage === 1
			? "text-white"
			: "text-white text-opacity-50 cursor-pointer";
	pages.push(
		<span key={number.toString()}>
			<span
				role="button"
				id="before"
				className={classNames}
				onClick={props.handleClick}>
				&lt;
			</span>
			&#160;&#160;&#160;
		</span>,
	);

	if (props.maxPages > 4) {
		let number = 1;
		let classNames =
			props.currentPage === number
				? "text-white"
				: "text-white text-opacity-50 cursor-pointer";
		pages.push(
			<span key={number.toString()}>
				<span
					id={number.toString()}
					className={classNames}
					onClick={props.handleClick}>
					{number}
				</span>
				&#160;&#160;&#160;
			</span>,
		);
		if (props.currentPage > 3) {
			number = 2;
			pages.push(
				<span key={number.toString()} className="text-white">
					.&#160;.&#160;.&#160;
				</span>,
			);
		}
		for (
			let number = props.currentPage - 1;
			number <= props.currentPage + 1;
			number++
		) {
			if (number > 1 && number < props.maxPages) {
				classNames =
					props.currentPage === number
						? "text-white"
						: "text-white text-opacity-50 cursor-pointer";

				pages.push(
					<span key={number.toString()}>
						<span
							id={number.toString()}
							className={classNames}
							onClick={props.handleClick}>
							{number}
						</span>
						&#160;&#160;&#160;
					</span>,
				);
			}
		}
		if (props.maxPages - props.currentPage > 3) {
			number = props.maxPages - 1;
			pages.push(
				<span key={number.toString()} className="text-white">
					.&#160;.&#160;.&#160;
				</span>,
			);
		}
		number = props.maxPages;
		classNames =
			props.currentPage === number
				? "text-white"
				: "text-white text-opacity-50 cursor-pointer";

		pages.push(
			<span key={number.toString()}>
				<span
					id={number.toString()}
					className={classNames}
					onClick={props.handleClick}>
					{number}
				</span>
				&#160;&#160;&#160;
			</span>,
		);
	} else {
		for (let number = 1; number <= props.maxPages; number++) {
			const classNames =
				props.currentPage === number
					? "text-white"
					: "text-white text-opacity-50 cursor-pointer";
			pages.push(
				<span key={number.toString()}>
					<span
						id={number.toString()}
						className={classNames}
						onClick={props.handleClick}>
						{number}
					</span>
					&#160;&#160;&#160;
				</span>,
			);
		}
	}

	number = props.maxPages + 1;
	classNames =
		props.currentPage === props.maxPages
			? "text-white"
			: "text-white text-opacity-50 cursor-pointer";
	pages.push(
		<span key={number.toString()}>
			<span id="next" className={classNames} onClick={props.handleClick}>
				&gt;
			</span>
			&#160;&#160;&#160;
		</span>,
	);

	return pages;
}
