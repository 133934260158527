import logo from "../../assets/shared/logo-no-button.svg";
import hamburger from "../../assets/shared/hamburger.svg";
import { Link as RouterLink } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

interface Link {
	href: string;
	value: string;
	comingSoon?: boolean;
}

export default function Navbar() {
	const navbarLinks: Link[] = [
		{
			href: "https://battle-drones.gitbook.io/whitepaper/",
			value: "Whitepaper",
		},
		{ href: "#", value: "Marketplace", comingSoon: true },
	];

	return (
		<>
			<nav className="h-20 md:h-24 grid items-center w-screen fixed z-[100] bg-gradient-to-b from-black/50 to-transparent">
				<div className="mx-4 flex justify-between font-medium lg:text-lg md:mx-20 space-x-8 text-white items-center place-items-center">
					<div className="flex space-x-12 items-center place-items-center">
						<RouterLink to="/">
							<img
								className="w-8 xl:w-10 h-auto hover:scale-110"
								src={logo}
								alt="Battle Drones"
							/>
						</RouterLink>
						<div className="flex space-x-12">{useLinks(navbarLinks)}</div>
					</div>{" "}
					<div className="flex space-x-4">
						<a href="/rare" target="_blank" rel="noreferrer">
							<div className=" hidden lg:block">
								{" "}
								<div className="box-border bg-orange h-[48px] w-[12rem] rounded-[4px] flex">
									<div className="m-auto">
										<p className="text-center text-lg  font-['Exo']">RARITY</p>
									</div>
								</div>
							</div>
						</a>
						<Menu as="div" className="relative">
							<Menu.Button className="focus:outline-none">
								<img
									className="w-8 h-auto hover:scale-110 transition-transform lg:hidden"
									src={hamburger}
									alt="Menu"
								/>
							</Menu.Button>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95">
								<Menu.Items className="absolute right-0 w-56 mt-4 origin-top-right bg-black divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
									<div className="px-1 py-1 ">
										<Menu.Item>
											{({ active }) => (
												<a
													className={`${
														active
															? "bg-white text-gray-900"
															: "text-white bg-black`"
													} group grid rounded-md items-center justify-items-center w-full p-4 text-sm font-bold text-center`}
													href="https://battle-drones.gitbook.io/whitepaper/"
													target="_blank"
													rel="noreferrer">
													Whitepaper
												</a>
											)}
										</Menu.Item>
										<Menu.Item>
											{({ active }) => (
												<a
													className={`${
														active
															? "bg-white text-gray-900"
															: "text-white bg-black`"
													} group grid rounded-md items-center justify-items-center w-full p-4 text-sm font-bold text-center`}
													href="/rare"
													target="_blank"
													rel="noreferrer">
													Rarity
												</a>
											)}
										</Menu.Item>
										<Menu.Item>
											{({ active }) => (
												<button
													disabled
													className={`${
														active
															? "bg-white text-gray-900"
															: "text-white bg-black`"
													} group grid rounded-md items-center justify-items-center w-full p-4 text-sm font-bold `}>
													Marketplace (Coming Soon)
												</button>
											)}
										</Menu.Item>
									</div>
								</Menu.Items>
							</Transition>
						</Menu>
					</div>
				</div>
			</nav>
		</>
	);
}

function useLinks(links: Link[]): JSX.Element[] {
	return links.map((link: Link, index: number) => {
		return (
			<a
				key={`${index}`}
				href={link.href}
				target="_blank"
				rel="noreferrer"
				className={`bg-transparent hidden lg:block ${
					!link.comingSoon ? "hover:scale-110" : ""
				} transition uppercase font-medium`}>
				{!link.comingSoon && link.value}
				{link.comingSoon && (
					<>
						<span className="flex flex-col">
							<span>{link.value}</span>
							<span className="text-sm text-right font-['Exo']">
								Coming Soon
							</span>
						</span>
					</>
				)}
			</a>
		);
	});
}
