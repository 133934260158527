import React, { useEffect, useState } from "react";
import rarityAttributes from "../../../../assets/rarity/attributeRarity.json";
import { RarityModalProps } from "./ModalTypes";

export default function ModalDesktop(props: RarityModalProps) {
	const [classes, setClasses] = useState(
		"hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full",
	);
	const findrarity = (layer: string, attribute: string) => {
		for (let i = 0; i < rarityAttributes.length; i++) {
			if (rarityAttributes[i].layer === layer) {
				for (let j = 0; j < rarityAttributes[i].type.length; j++) {
					if (rarityAttributes[i].type[j].layer === attribute) {
						return `1 of ${rarityAttributes[i].type[j].qt}`;
					}
				}
			}
		}
	};

	const displayAttributes = () => {
		return props.drone.attributes.map((attribute, index) => {
			return (
				<div key={`${index}`} className="text-l">
					<div className="text-left font-normal py-[0.5rem]">
						{attribute.trait_type}
					</div>
					<div className="flex bg-white/[14%] rounded-[4px] py-[0.25rem] px-[1rem] text-l">
						<div className="flex-1 text-md font-['Exo'] font-semibold text-left opacity-[70%]">
							{attribute.value}
						</div>
						<div className="flex-1 text-md font-['Exo'] font-semibold text-right opacity-[70%]">
							{findrarity(attribute.trait_type, attribute.value)}
						</div>
					</div>
				</div>
			);
		});
	};
	const imageSource =
		"assets/nftImages/" + props.drone.name.split("#")[1] + ".png";

	const findNumber = () => {
		const indexHash = props.drone.name.indexOf("#");
		return props.drone.name.slice(indexHash);
	};
	useEffect(() => {
		const all =
			"flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none text-center focus:outline-none";
		const body = document.querySelector("body");
		if (props.show) {
			setClasses(`backdrop-blur-sm ${all}`);
			if (body) {
				body.style.overflow = "hidden";
			}
		} else {
			setClasses(`hidden ${all}`);
			if (body) {
				body.style.overflow = "auto";
			}
		}
	}, [props.show]);
	return (
		<div className={classes} onClick={props.handleClose}>
			<div
				className="relative w-auto my-6 mx-auto"
				onClick={e => {
					e.stopPropagation();
				}}>
				<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none w-[65vw] bg-black text-white">
					<div className="flex items-start pt-4 pr-4 rounded-t">
						<button
							type="button"
							className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
							onClick={props.handleClose}>
							<svg
								className="w-5 h-5"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clip-rule="evenodd"></path>
							</svg>
						</button>
					</div>
					<div className="flex items-start justify-center p-7 pt-0 px-[4rem] ">
						<div className="flex-1 text-xl font-['Exo'] font-semibold float-center pr-[1rem]">
							<div className="flex">
								<div className="flex-1 font-['Exo'] font-semibold text-left">
									Rarity Rank{" "}
									<span className="text-orange">#{props.drone.rank}</span>
								</div>
								<div className="flex-1 font-['Exo'] font-semibold text-right">
									ID:{findNumber()}
								</div>
							</div>
							<div className="py-[1rem]">
								<img src={imageSource} alt={props.drone.name} />
							</div>
							<div className="flex">
								<div className="flex-1 font-['Exo'] font-semibold text-left">
									Score
								</div>
								<div className="flex-1 font-['Exo'] font-semibold text-right">
									{props.drone.score.toFixed(1)}
								</div>
							</div>
						</div>
						<div className="flex-1 font-['Exo'] font-semibold float-center pl-[1rem]">
							<div className="text-left text-xl pb-[0.5rem]">Attributes:</div>
							{displayAttributes()}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
