import { Route, Routes } from "react-router";
import Layout from "./components/layout/layout";
import Home from "./routes/home/home";
import Rarity from "./routes/rarity/rarity";
import Roadmap from "./routes/roadmap/roadmap";

function App() {
	return (
		<div>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />}></Route>
					<Route path="roadmap" element={<Roadmap />}></Route>
					<Route path="rare" element={<Rarity />}></Route>
				</Route>
			</Routes>
		</div>
	);
}

export default App;
