import React from "react";
import { RarityFilterProps } from "./FilterTypes";
import Select from "react-select";

export default function FilterDesktop(props: RarityFilterProps) {
	const customStyles = {
		valueContainer: (provided: any, state: any) => ({
			...provided,
			border: 0,
			background: "linear-gradient(#2D2D2D, #000000)",
		}),
		singleValue: (provided: any, state: any) => ({
			...provided,
			color: "#ffffff",
			background: "linear-gradient(#2D2D2D, #000000)",
		}),
		menu: (provided: any, state: any) => ({
			...provided,
			color: "#ffffff",
			background: "linear-gradient(#2D2D2D, #000000)",
		}),
		dropdownIndicator: (provided: any, state: any) => ({
			...provided,
			color: "#ffffff",
			background: "linear-gradient(#2D2D2D, #000000)",
		}),
		container: (provided: any, state: any) => ({
			...provided,
			border: 0,
		}),
		control: (provided: any, state: any) => ({
			...provided,
			background: "linear-gradient(#2D2D2D, #000000)",
			"border-radius": "4px",
			"border-width": "1px",
			"border-color": "#6b7280",
		}),
	};
	// const themeChose = (theme: any) => {
	// 	console.log(theme);
	// 	return {
	// 		...theme,
	// 		colors: {
	// 			...theme.colors,
	// 			primary25: "grey",
	// 			primary: "#2D2D2D",
	// 		},
	// 	};
	// };

	return (
		<div className="flex flex-row w-full">
			<div className="flex-1">
				<input
					className="m-0 font-['Exo'] py-[4px] px-[10px] rounded-[4px] h-[100%] w-[75%] min-w-[330px] font-normal bg-gradient-to-t from-black to-boxBlack text-white placeholder:text-white"
					placeholder={props.search.placeholder}
					type="search"
					onChange={props.search.onChange}
					value={props.search.value}
				/>
			</div>
			<div className="flex-1">
				<div className="m-auto mr-0 font-['Exo'] rounded-[4px] h-[100%] w-[50%] min-w-[330px] font-normal bg-gradient-to-t from-black to-boxBlack text-white placeholder:text-white">
					<Select
						className="m-0  bg-gradient-to-t from-black to-boxBlack text-white placeholder:text-white border-0"
						id="selectMenu"
						// menuIsOpen={true}
						styles={customStyles}
						options={props.select.options}
						closeMenuOnSelect={props.select.closeMenuOnSelect}
						onChange={props.select.onChange}
						defaultValue={props.select.defaultValue}
					/>
				</div>
			</div>
		</div>
	);
}
