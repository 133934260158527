import React from "react";

export interface NftGridItemProps {
	timeout: any;
	index: number;
	drone: {
		name: string;
		rank: number;
		score: number;
	};
	onClick: (event: any) => void;
	loaded: boolean[];
	maximumNumberOfItems: number;
}

export default function NftGridItem(props: NftGridItemProps) {
	const droneNumber = "#" + props.drone.name.split("#")[1];
	const imageSource =
		"assets/nftImages/" + props.drone.name.split("#")[1] + ".png";

	return (
		<div
			id={`image-${props.index}`}
			className="lg:w-[25%] px-[16px] w-full md:w-[50%]"
			key={props.index.toString()}>
			<div className="card-wrap">
				{/* <button onClick={props.onClick} className="d-inline-block "> */}
				<button className="d-inline-block" onClick={props.onClick}>
					<div className="image-wrap">
						<img
							src={imageSource}
							style={{ borderRadius: "10px" }}
							alt={props.drone.name}
							onLoad={() => {
								props.loaded[props.index % props.maximumNumberOfItems] = true;

								document
									.getElementById(`image-${props.index}`)
									?.classList.remove("hidden");
								document
									.getElementById(`image-${props.index}`)
									?.classList.add("block");

								if (
									props.loaded.every((element: boolean) => element === true)
								) {
									clearTimeout(props.timeout);
									document
										.getElementById("nft-rarity-spinner")
										?.classList.remove("block");
									document
										.getElementById("nft-rarity-spinner")
										?.classList.add("hidden");
									document
										.getElementById("nft-rarity-content")
										?.classList.remove("hidden");
									document
										.getElementById("nft-rarity-pages")
										?.classList.remove("hidden");
								}
							}}
						/>
					</div>
				</button>
				<div className="content-wrap text-center px-[1rem] py-[2rem]">
					<h6
						className=""
						style={{ fontFamily: "Exo", fontWeight: "bold", color: "white" }}>
						{droneNumber}
					</h6>
					<h6
						className=""
						style={{ fontFamily: "Exo", fontWeight: 600, color: "white" }}>
						Rank: {props.drone.rank}
					</h6>
					<h6
						className="d"
						style={{ fontFamily: "Exo", fontWeight: 400, color: "white" }}>
						Score: {props.drone.score.toFixed(1)}
					</h6>
				</div>
			</div>
		</div>
	);
}
