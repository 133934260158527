import { Helmet, HelmetProvider } from "react-helmet-async";
import { motion } from "framer-motion";
import roadmap from "../../assets/roadmap/roadmap.png";
import roadmapPhone from "../../assets/roadmap/roadmap-phone.png";

export default function Roadmap() {
	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>Battle Drones | Roadmap</title>
				</Helmet>
			</HelmetProvider>

			<div className="bg-black">
				<motion.section
					initial={{ opacity: 0, x: -100 }}
					animate={{ opacity: 1, x: 0 }}
					transition={{ duration: 0.5 }}
					viewport={{ once: true, amount: 0.4 }}>
					<img
						className="w-full h-full hidden lg:block"
						src={roadmap}
						alt="Roadmap"></img>
					<img
						className="w-full h-full block lg:hidden"
						src={roadmapPhone}
						alt="Roadmap"></img>
				</motion.section>
			</div>
		</>
	);
}
